@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .pointer-events-all {
    pointer-events: all;
  }

  .noArrow::-webkit-inner-spin-button,
  .noArrow::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .full-shadow {
    box-shadow: 0px 4px 12px rgba(32, 35, 37, 0.2);
  }

  .scrollbar-rounded::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }

  .scrollbar-height::-webkit-scrollbar {
    height: 4px;
  }

  .h-nav {
    height: calc(100vh - 4rem);
  }
}

@layer base {
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }

  body {
    font-family: 'SFProDisplay', sans-serif;
  }

  input[type='range']::-webkit-slider-thumb {
    width: 4px;
    -webkit-appearance: none;
    appearance: none;
    height: 4px;
    cursor: ew-resize;
    background: #fff;
    box-shadow: -405px 0 0 400px #000000;
    border-radius: 100%;
  }
}

@font-face {
  font-family: 'SFProDisplay';
  src:
    url('fonts/SFProDisplay-Regular.woff2') format('woff2'),
    url('fonts/SFProDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProDisplay';
  src:
    url('fonts/SFProDisplay-RegularItalic.woff2') format('woff2'),
    url('fonts/SFProDisplay-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SFProDisplay';
  src:
    url('fonts/SFProDisplay-Medium.woff2') format('woff2'),
    url('fonts/SFProDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProDisplay';
  src:
    url('fonts/SFProDisplay-MediumItalic.woff2') format('woff2'),
    url('fonts/SFProDisplay-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SFProDisplay';
  src:
    url('fonts/SFProDisplay-BoldItalic.woff2') format('woff2'),
    url('fonts/SFProDisplay-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SFProDisplay';
  src:
    url('fonts/SFProDisplay-Bold.woff2') format('woff2'),
    url('fonts/SFProDisplay-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
